<template>
  <form class="form" novalidate="novalidate" id="st_legal_applicant_form">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>
            {{ fields.first_name.label }}
          </label>
          <st-input-text
            :ref="fields.first_name.name"
            :name="fields.first_name.name"
            v-model="model[fields.first_name.name]"
            :disabled="viewMode"
          />
        </div>

        <div class="form-group">
          <label>
            {{ fields.last_name.label }}
          </label>
          <st-input-text
            :ref="fields.last_name.name"
            :name="fields.last_name.name"
            v-model="model[fields.last_name.name]"
            :disabled="viewMode"
          />
        </div>
        <div class="form-group mt-4" v-if="!viewMode && !isStaff">
          <b-form-checkbox
            :ref="fields.is_contact_person.name"
            :name="fields.is_contact_person.name"
            v-model="model[fields.is_contact_person.name]"
            size="lg"
            :disabled="viewMode"
            @change="onChangeContactPerson"
          >
            {{ fields.is_contact_person.label }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>
            {{ fields.email.label }}
          </label>
          <div>
            <st-input-text
              :ref="fields.email.name"
              :name="fields.email.name"
              v-model="model[fields.email.name]"
              :disabled="viewMode"
            />
          </div>
        </div>

        <div class="form-group">
          <label>
            {{ fields.phone_number.label }}
          </label>
          <div>
            <st-input-text
              :ref="fields.phone_number.name"
              :name="fields.phone_number.name"
              v-model="model[fields.phone_number.name]"
              :disabled="viewMode"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>
            {{ fields.license.label }}
          </label>
          <st-input-text
            :ref="fields.license.name"
            :name="fields.license.name"
            v-model="model[fields.license.name]"
            :disabled="viewMode"
          />
        </div>
      </div>
      <div class="mt-4"></div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { ApplicantModel } from "@/modules/applications/models/applicant-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from '@/shared/utils/create-form-validation';

const { fields } = ApplicantModel;
const formSchema = new FormSchema([
  fields.license,
  fields.first_name,
  fields.last_name,
  fields.email,
  fields.phone_number,
  fields.is_contact_person,
]);

export default {
  name: "LegalApplicantForm",
  components: {},
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: [],
      fields: fields,
      rules: formSchema.rules(),
    };
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit("change", val);
      },
    },
  },
  computed: {
    ...mapGetters({
      record: "applications/form/record",
      currentUser: "auth/currentUser",
      isStaff: "auth/isStaff",
    }),
  },
  methods: {
      onChangeContactPerson() {
      if (this.model.is_contact_person) {
        this.populateFormDataWithCurrentUserData();
        return;
      }
      this.clearForm();
    },
    populateFormDataWithCurrentUserData() {
      this.model = this.getInitialModelValues();
    },
    getInitialModelValues() {
      if (this.isStaff) {
        return {
          ...this.record.application_beneficiary,
          user_type_name: "legal_entity"
        };
      }

      const { license, subsidiary, first_name, last_name, email, phone_number } = this.currentUser;
      return {
        license,
        subsidiary,
        first_name,
        last_name,
        email,
        phone_number,
        is_contact_person: true,
        user_type_name: "legal_entity"
      };
    },
    clearForm() {
      this.model = formSchema.initialValues({});
      this.fv.resetForm(true);
    },
  },
  created() {
    const record = this.record?.form?.applicant_user ? this.record.form.applicant_user : this.getInitialModelValues();
    this.model = formSchema.initialValues(record);
  },
  mounted() {
    this.fv = createFormValidation("st_legal_applicant_form", this.rules);
  },
};
</script>
